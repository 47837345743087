import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import React from "react";
import { Container } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
function Terms() {
  const [search] = useSearchParams();

  const type = React.useMemo(() => search.get("type"), [search]);

  const termLink = React.useMemo(
    () => (type === "privacy" ? "/terms/privacy.pdf" : "/terms/terms.pdf"),
    [type]
  );

  return (
    <Container className="py-5">
      <h2>
        {type === "privacy" ? "Política de Privacidade" : "Termos de Uso"}
      </h2>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
        <div
          style={{
            width: "100%",
            height: "100vh",
          }}
        >
          <div
            style={{
              marginLeft: -150,
              marginTop: 20,
              height: "100vh",
            }}
          >
            <Viewer fileUrl={termLink} />;
          </div>
        </div>
      </Worker>
    </Container>
  );
}

export default Terms;
