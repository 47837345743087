import React, { useState } from "react";
import { Collapse, Card, Button, Container } from "react-bootstrap";
import FrontAndBack from "../../assets/images/faq/cnh.png";

const faqs = [
  {
    question: "Como se dá a abertura da conta Prosegur Digital?",
    answer:
      "O processo é totalmente digital, via aplicativo ou internet banking.",
  },
  {
    question: "Posso abrir uma conta Pessoa Física na Prosegur Digital?",
    answer:
      "Não, a conta Prosegur Digital está disponível somente para Pessoa Jurídica.",
  },
  {
    question: "Como posso acessar minha conta Prosegur Digital?",
    answer:
      "Você pode acessar sua conta através do aplicativo móvel ou internet banking da Prosegur Digital. No caso do aplicativo móvel, basta instalar o app “Prosegur Digital”, disponível na loja de aplicativos. A versão mínima requerida do sistema operacional do celular iOS é 12.4; do celular Android é 5. Para acessar via internet banking, o endereço é www.prosegurdigital.com.br.",
  },
  {
    question: "Como entrar em contato com o SAC Prosegur Digital?",
    answer:
      "Os telefones são: SAC: 0800 237 1321; Deficiente auditivo/fala: 0800 237 1317. Atendimento 24 horas.",
  },
  {
    question:
      "A minha solicitação de abertura de conta não foi aprovada, o que devo fazer?",
    answer:
      "Entre em contato com o SAC Prosegur Digital para obter assistência. Os telefones são: SAC: 0800 237 1321 / Deficiente auditivo/fala: 0800 237 1317. Atendimento 24 horas.",
  },
  {
    question: "Como cancelar a conta Prosegur Digital?",
    answer:
      "Entre em contato com o SAC Prosegur Digital. Os telefones são: SAC: 0800 237 1321 / Deficiente auditivo/fala: 0800 237 1317. Atendimento 24 horas. Você também pode fazer a solicitação via aplicativo: acesse a opção “Perfil” e em seguida “Cancelar conta”. Via internet banking, acesse a opção “Perfil” e em seguida “Encerrar minha conta”.",
  },
  {
    question:
      "Quais transações da conta Prosegur Digital têm cobrança de tarifas?",
    answer:
      "As transações podem ou não ter cobrança de tarifas, conforme descrito na Tabela de Tarifas, que fica disponível no aplicativo móvel ou internet banking da Prosegur Digital.",
  },
  {
    question:
      "Como acessar o Informe de Rendimentos para Imposto de Renda na conta digital Prosegur Digital?",
    answer:
      'É possível solicitar o Informe de Rendimentos através do aplicativo móvel ou internet banking da Prosegur Digital, na seção "Perfil".',
  },
  {
    question: "Como fazer depósitos na conta digital Prosegur Digital?",
    answer:
      "É possível depositar na conta através dos cofres Prosegur Cash Today, através de transferência bancária (TED ou Pix) ou via pagamento de boleto gerado pela conta Prosegur Digital.",
  },
  {
    question: "Como funciona o depósito em boleto?",
    answer:
      "O cliente gera um boleto na Prosegur Digital com o valor desejado, cujo pagamento pode ser feito em qualquer banco, casa lotérica ou correspondente bancário. O saldo da conta Prosegur Digital será carregado em até 48 horas após o pagamento do respectivo boleto.",
  },
  {
    question: "Como fazer para gerar um boleto?",
    answer:
      'Acesse a opção "Receber" e selecione a opção "Gerar um boleto de depósito". Em seguida, digite o valor desejado e avance para gerar o boleto.',
  },
  {
    question:
      "Quais são os limites de transferência entre contas Prosegur Digital?",
    answer:
      "Os limites padrão são: R$ 10.000,00 por transação, R$ 20.000,00 por dia e R$ 600.000,00 por mês. No período noturno, o limite é de R$ 1.000,00. Esses limites podem ser alterados mediante solicitação.",
  },
  {
    question: "Quais são os limites de transferências TED (saída)?",
    answer:
      "Os limites padrão são: R$ 10.000,00 por transação, R$ 20.000,00 por dia e R$ 600.000,00 por mês. No período noturno, o limite é de R$ 1.000,00. Esses limites podem ser alterados mediante solicitação.",
  },
  {
    question: "Quais são os limites de transferências Pix (saída)?",
    answer:
      "Os limites padrão são: R$ 10.000,00 por transação, R$ 20.000,00 por dia e R$ 600.000,00 por mês. No período noturno, o limite é de R$ 1.000,00. Esses limites podem ser alterados mediante solicitação.",
  },
  {
    question:
      "Quais documentos e dados são necessários para abrir uma conta Pessoa Jurídica na Prosegur Digital?",
    answer: [
      <h6>Sobre a empresa</h6>,
      "CNPJ, E-mail, Celular, Razão Social, Nome Fantasia, Inscrição Estadual(Opcional), Data de Abertura da Empresa, Tipo da Empresa(Ltda, S.A Etc.), CNAE, Faturamento Mensal",
      <h6>Cadastro de sócios</h6>,
      "CPF, Nome completo, Data de Nascimento, Nome completo da mãe, Tipo de representante(sócio ou procurador), Porcentagem do representante, Endereo residencial do representante, Aceite de Termos de Uso e Política de Privacidade, Definição do login para acesso, Definição da senha para acesso, Endereço comercial",
      "Upload do contrato social, selfie do representante, foto da CNH ou RG, Comprovante de inscrição e situação cadastral da empresa",
      "É importante ter o celular, pois irá receber notificações e o código para acessar ou transacionar na conta digital",
      "A inclusão da selfie deve ser feita em tempo real, segurando o celular na frente do rotos, fazendo as aproximações solicitadas, em local com boa iluminação, sem refleos ou sombras, com o rosto visível e sem o uso de acessórios ou filtros",
      "Não pode anexar ou tirar uma foto de uma foto já existente, como foto de perfil de redes sociais, por exemplo",
      <h6>Sobre o envio de foto da CNH ou RG</h6>,
      "A foto do documento do sócio (RG, CNH, Passaporte ou CNH digital com o QR Code, com prazo de emissão de no máximo 10 anos) deve abranger o documento inteiro, com uma boa iluminação, sem reflexos ou sombras e sem cortar nenhuma parte das bordas",
      <h6>Frente/Verso</h6>,
      "É necessário fazer upload de 2 arquivos: um da frente do documento e outro arquivo com o verso do documento, conforme o exemplo abaixo:",
      <img
        src={FrontAndBack}
        alt="Frente e Verso"
        style={{ maxWidth: "40%", height: "auto" }}
      />,
      <h6>Opção de Envio da CNH-e Digital</h6>,
      "Com opção, pode ser utilizada a CNH-e Digital",
      "Acessar o aplicativo Carteira Digital de Trânsito, Clicar em Condutor, Clicar na opção habilitação(Caso ainda não tenha cadastro, basta seguir as intruções do aplicativo), clicar na opção Exportar, Clicar novamente em Exportar e será direcionado às  opçoes disponíveis no celular para transmitir ou armazenar",
      <a
        target="_blank"
        href="https://portalservicos.senatran.serpro.gov.br/static/carteiradigital/tutoriais/html/demo_12.html"
      >
        Clique aqui para passo a passo de como exportar CNH-e digital
      </a>,
      <h6>Para instalar o aplicativo CNH-e Digital</h6>,
      <a
        target="_blank"
        href="https://apps.apple.com/br/app/carteira-digital-de-tr%C3%A2nsito/id1275057217"
      >
        Apple
      </a>,
      <a
        target="_blank"
        href="https://play.google.com/store/apps/details?id=br.gov.serpro.cnhe&pli=1"
      >
        Android
      </a>,
      "Aceitamos: RG/CPF, CNH/CNH Digital (com QR Code), Passaporte Nacional, RNE/RNM, todos com data de expedição inferior a 10 anos.",
      "Não aceitamos: XEROX, SCAN, CÓPIA, FOTO DE FOTO.",
    ],
  },
];

const HelpPage = () => {
  return (
    <Container className="py-5">
      <h1 className="mb-3">Perguntas Frequentes:</h1>
      {faqs.map((faq, index) => (
        <FaqItem key={index} question={faq.question} answer={faq.answer} />
      ))}
    </Container>
  );
};

const FaqItem = ({ question, answer }) => {
  const [open, setOpen] = useState(false);

  return (
    <Card className="mb-3">
      <Card.Header>
        <Button
          variant="link"
          onClick={() => setOpen(!open)}
          aria-controls="faq-answer"
          aria-expanded={open}
          className="text-dark fw-bold text-decoration-none"
        >
          {question}
        </Button>
      </Card.Header>
      <Collapse in={open}>
        <Card.Body id="faq-answer">
          {Array.isArray(answer)
            ? answer.map((answer) => <p>{answer}</p>)
            : answer}
        </Card.Body>
      </Collapse>
    </Card>
  );
};

export default HelpPage;
